import PaymentAPIs from "../APIs/PaymentAPIs";
import IpStorage from "./IpStorage";

/**
 * Logger class.
 */
class Logger {
    /**
     * Add log on API
     *
     * @param message
     * @param headers
     */
    public async log(message: string, headers: object | null = null) {
        const body = {
            message: message,
            ipClient: IpStorage.getIpClient(),
        };

        return PaymentAPIs.postLogPayment(body, headers);
    }
}

export default new Logger();
