import GenericAPIs from './GenericAPIs';
import JwtStorage from "../helpers/JwtStorage";

/**
 * PaymentAPIs class.
 */
export default class PaymentAPIs {

    /**
     * Url on API for the Payment resource
     */
    private static resourceUrl: string = 'payment';

    /**
     * Post informations Payment.
     *
     * @param body
     */
    public static async postInformationsPayment(body: any) {
        GenericAPIs.headers = JwtStorage.getAuthorizationHeader();

        // do not notify on success
        return GenericAPIs.apiPostCall(`${this.resourceUrl}/informations`, body, false);
    }

    /**
     * Post a session Payment.
     *
     * @param body
     */
    public static async postSessionPayment(body: any) {
        GenericAPIs.headers = JwtStorage.getAuthorizationHeader();

        // do not notify on success
        return GenericAPIs.apiPostCall(`${this.resourceUrl}/session`, body, false);
    }

    /**
     * Post log Payment.
     *
     * @param body
     * @param authHeaders
     */
    public static async postLogPayment(body: any, authHeaders: object | null = null) {
        GenericAPIs.headers = JwtStorage.getAuthorizationHeader()

        if (null !== authHeaders) {
            GenericAPIs.headers = authHeaders;
        }

        // do not notify on success
        return GenericAPIs.apiPostCall(`${this.resourceUrl}/log`, body, false, true, false);
    }

    /**
     * Post success Payment.
     *
     * @param body
     * @param authHeaders
     */
    public static async postSucceededPayment(body: any, authHeaders: object | null = null) {
        GenericAPIs.headers = authHeaders;

        // do not notify on success
        return GenericAPIs.apiPostCall(`${this.resourceUrl}/succeeded`, body, false, false);
    }

    /**
     * Post canceled Payment.
     *
     * @param body
     * @param authHeaders
     */
    public static async postCanceledPayment(body: any, authHeaders: object | null = null) {
        GenericAPIs.headers = authHeaders;

        // do not notify on success
        return GenericAPIs.apiPostCall(`${this.resourceUrl}/canceled`, body, false, false);
    }
};
