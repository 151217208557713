import React, {useEffect, useState} from 'react';
import {Formik, FormikValues, FormikProps} from "formik";
import * as Yup from 'yup';
import {useHistory} from "react-router-dom";
import IGenericFormValues from "../../../interfaces/IGenericFormValues";
import ITransaction from "../../../interfaces/ITransaction";
import PaymentService from "../../../services/PaymentService";
import HeadbandStep from "../../heandbandStep/HeadbandStep";
import MinimalForm from "../minimalForm/MinimalForm";
import Spinner from "../../spinner/Spinner";
import './GenericForm.scss';

const GenericForm: React.FC<{ typeForm: string, transaction: ITransaction }> = props => {
    const defaultValues: IGenericFormValues = {
        civility: 1,
        lastname: '',
        firstname: '',
        email: '',
    };
    const [initialValues, setInitialValues] = useState<any>(defaultValues);
    const [isShowForm, setIsShowForm] = useState<boolean>(false);

    // Use of react-router-dom
    const history = useHistory();

    // Set initial value for the formik form
    useEffect(() => {
        // Inital values for formik form
        const values = {
            civility: props.transaction && null !== props.transaction.civility ? props.transaction.civility : defaultValues.civility,
            lastname: props.transaction && null !== props.transaction.lastname ? props.transaction.lastname : defaultValues.lastname,
            firstname: props.transaction && null !== props.transaction.firstname ? props.transaction.firstname : defaultValues.firstname,
            email: props.transaction && null !== props.transaction.email ? props.transaction.email : defaultValues.email,
            phone: props.transaction?.phone,
            companyName: props.transaction?.companyName,
            sirenSiret: props.transaction?.sirenSiret,
            billingAddressLine1: props.transaction?.billingAddress?.line1,
            billingAddressPostalCode: props.transaction?.billingAddress?.postalCode,
            billingAddressCity: props.transaction?.billingAddress?.city,
            billingAddressCountry: props.transaction?.billingAddress?.country,
            shippingAddressLine1: props.transaction?.order.shipping?.address?.line1,
            shippingAddressPostalCode: props.transaction?.order.shipping?.address?.postalCode,
            shippingAddressCity: props.transaction?.order.shipping?.address?.city,
            shippingAddressCountry: props.transaction?.order.shipping?.address?.country,
            shippingName: props.transaction?.order.shipping?.name,
            shippingPhone: props.transaction?.order.shipping?.phone,
        };

        setInitialValues(values);
        setIsShowForm(true);
    }, [defaultValues.civility, defaultValues.email, defaultValues.firstname, defaultValues.lastname, props.transaction]);

    const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const sirenSiretRegex = /^\d+$/;

    // Validation scheme of the formik form
    const validationSchema = Yup.object({
        civility: Yup.number().required('Veuillez spécifier la civilité'),
        lastname: Yup.string().required('Veuillez spécifier le nom'),
        firstname: Yup.string().required('Veuillez spécifier le prenom'),
        email: Yup.string().email('Email invalide').required('Veuillez spécifier l\'email'),
        phone: Yup.string().matches(phoneRegex).nullable(true),
        companyName: Yup.string().nullable(true),
        sirenSiret: Yup.string().max(14).matches(sirenSiretRegex).nullable(true),
        billingCity: Yup.string().nullable(true),
        billingCountry: Yup.string().nullable(true),
        billingLine1: Yup.string().nullable(true),
        billingPostalCode: Yup.string().nullable(true),
        shippingCity: Yup.string().nullable(true),
        shippingCountry: Yup.string().nullable(true),
        shippingLine1: Yup.string().nullable(true),
        shippingPostalCode: Yup.string().nullable(true),
        shippingName: Yup.string().nullable(true),
        shippingPhone: Yup.string().matches(phoneRegex).nullable(true),
    });

    // Show confirmation information payment
    const handleSubmit = async (values: FormikValues) => {
        const transactionToken: string | null = props.transaction.token;

        if (null === transactionToken) {
            return false;
        }

        const transaction: ITransaction = await PaymentService.postInformations(values, transactionToken);

        if (null === transaction) {
            return false;
        }

        history.push(`/confirmation?transactionToken=${props.transaction.token}`);
    };

    return (
        <>
            <HeadbandStep type={`payment`} step={`informations`}/>
            {

                isShowForm ?
                    <div className="blockContainer_default">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async values => {
                                await handleSubmit(values);
                            }}
                        >
                            {(props: FormikProps<any>) => (
                                <MinimalForm formikProps={props}/>
                            )}
                        </Formik>
                    </div>
                    :
                    <Spinner msg="Chargement du formulaire"/>
            }
        </>
    );
};

export default GenericForm;
