import queryString, {ParsedQuery} from "query-string";

/**
 * UrlDecoder class.
 */
export default class UrlQueryDecoder {
    urlParams: ParsedQuery;

    /**
     * Constructor.
     *
     * @param urlQuery
     */
    constructor(urlQuery: string) {
        const urlQueryDecoded = this.base64decode(urlQuery);
        this.urlParams = queryString.parse(urlQueryDecoded)
    }

    /**
     * Decode urlQuery.
     */
    public base64decode(urlQuery: string): string {
        return String(Buffer.from(urlQuery, 'base64'));
    }

    /**
     * Get transactionToken from urlQuery encoded.
     */
    public getTransactionToken(): string | null {
        return String(this.urlParams['transactionToken']) ?? null;
    }

    /**
     * Get jwt from urlQuery encoded.
     */
    public getJwt(): string | null {
        return String(this.urlParams['jwt']) ?? null;
    }

    /**
     * Get full jwt header.
     */
    public getAuthorizationHeader(): { headers: object } {
        return {
            headers: this.getAuthorization(),
        }
    }

    /**
     * Get jwt authorization.
     */
    public getAuthorization(): { JWT: string } {
        return {
            'JWT': this.getJwt() ?? '',
        };
    }
}
