import React, {useEffect, useRef, useState, useCallback} from 'react';
import ReactNotification from 'react-notifications-component';
import {Switch, Route, useLocation} from 'react-router-dom';
import JwtStorage from "../helpers/JwtStorage";
import typeFormToComponent from "../configs/typeFormToComponent";
import SucceededPayment from "./succeededPayment/SucceededPayment";
import CanceledPayment from "./canceledPayment/CanceledPayment";
import Home from "./home/Home";
import ConfirmationForm from "./forms/confirmationForm/ConfirmationForm";
import UrlInspector from "../helpers/UrlInspector";
import typeFormToLogo from "../configs/typeFormToLogo";
import HeadbandLogo from "./heandbandLogo/HeadbandLogo";
import IpAPIs from "../APIs/IpAPIs";
import Spinner from "./spinner/Spinner";
import IpStorage from "../helpers/IpStorage";
import InformationsForm from "./forms/informationsForm/InformationsForm";
import AppStorage from "../helpers/AppStorage";
import Logger from "../helpers/Logger";
import UrlQueryDecoder from "../helpers/UrlQueryDecoder";
import {AUTHENTICATION_ERROR, LOADING, LOADING_NOT_POSSIBLE, USER_ON_PAYMENT_MODULE} from "../helpers/Message";

const App: React.FC = () => {
    const location = useLocation();
    const urlInspector = new UrlInspector(location);
    const jwt: any = urlInspector.getParams('jwt') ?? JwtStorage.getJwt();
    const token: any = urlInspector.getParams('paymentToken');

    const [ipClient, setIpClient] = useState<string | undefined>(IpStorage.getIpClient());
    const [typeForm, setTypeForm] = useState<string | undefined>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [authenticationMsg, setAuthenticationMsg] = useState<string>(LOADING);
    const [infoMsg, setInfoMsg] = useState<string>(LOADING);

    const isFirst = useRef<boolean>(true);

    /**
     * Log Api call.
     *
     * @param errorAuthMsg
     * @param authHeaders
     */
    const logMessage = useCallback( async (errorAuthMsg: string, authHeaders: object | null) => {
        const logResponse = await Logger.log(USER_ON_PAYMENT_MODULE, authHeaders);

        if (null !== logResponse) {
            setIsAuthenticated(true);

            return true;
        }

        return false;
    }, [])

    /**
     * Log treatment.
     */
    const logTreatment = useCallback(async () => {
        let isLogged: boolean = isAuthenticated;
        const errorAuthMsg = AUTHENTICATION_ERROR;

        if (null !== token && !isLogged) {
            const urlQueryDecoder = new UrlQueryDecoder(token);
            const authHeaders = urlQueryDecoder.getAuthorizationHeader();

            isLogged = await logMessage(errorAuthMsg, authHeaders);
        }

        if (null !== jwt && !isLogged) {
            JwtStorage.storeJwt(jwt);

            isLogged = await logMessage(errorAuthMsg, null);
        }

        if (!isLogged) {
            setAuthenticationMsg(errorAuthMsg);
        }
    }, [isAuthenticated, jwt, token, logMessage])

    // Set new informations msg after 10 seconds
    useEffect(() => {
        setTimeout(() => {
            setInfoMsg(LOADING_NOT_POSSIBLE);
        }, 10000);
    });

    // Set jwt in local storage & Log informations & check authentication
    useEffect(() => {
        if (isFirst.current) {
            logTreatment();
        }

        isFirst.current = false;
    }, [logTreatment]);

    // Get & set typeForm
    useEffect(() => {
        if (typeof typeForm === 'undefined') {
            let tf: any = urlInspector.getParams('typeForm');

            if (null === tf || typeof tf === 'undefined') {
                tf = AppStorage.getTypeForm() ?? 'generic';
            }

            AppStorage.storeTypeForm(tf);
            setTypeForm(tf);
        }

    }, [typeForm, urlInspector]);

    // Get Ip Client
    useEffect(() => {
        if (typeof typeForm !== 'undefined') {
            let ipClient = '';
            IpAPIs.getIpv4()
                .then((response: any) => {
                    if (null !== response.data.ip && typeof response.data.ip !== 'undefined') {
                        ipClient = response.data.ip;
                    }

                    IpStorage.storeIpClient(ipClient);
                    setIpClient(ipClient);
                })
                .catch((error: any) => {
                    IpStorage.storeIpClient(ipClient);
                    setIpClient(ipClient);
                });
        }

    }, [typeForm]);

    return (
        <>
            {
                typeof typeForm !== 'undefined' && typeof ipClient !== 'undefined' && typeFormToComponent[typeForm] ?
                    <div className={typeForm}>
                        <ReactNotification/>
                        <Switch>
                            {isAuthenticated ?
                                <>
                                    {'/' !== location.pathname && <HeadbandLogo logo={typeFormToLogo[typeForm]}/>}
                                    <Route exact path="/">
                                        <Home/>
                                    </Route>
                                    <Route exact path="/informations">
                                        <InformationsForm
                                            typeForm={typeForm}
                                            component={typeFormToComponent[typeForm].component}
                                            transactionStatuses={typeFormToComponent[typeForm].transactionStatuses}
                                        />
                                    </Route>
                                    <Route exact path="/confirmation">
                                        <ConfirmationForm typeForm={typeForm}/>
                                    </Route>
                                    <Route exact path="/succeeded">
                                        <SucceededPayment/>
                                    </Route>
                                    <Route exact path="/canceled">
                                        <CanceledPayment/>
                                    </Route>
                                </>
                                :
                                <Spinner msg={authenticationMsg}/>
                            }
                        </Switch>
                    </div>
                    :
                    <Spinner msg={infoMsg}/>
            }
        </>
    );
};

export default App;
