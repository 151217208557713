import React from "react";
import "./Home.scss";

const Home: React.FC = () => {
    return (
        <div className="homeContainer">
            <div className="homeStructure">
                <img src={require(`../../img/logo-lbi.svg`)} alt="logo" className="logo"/>
                <h1 className="titre">Bienvenue sur LBI Paiement !</h1>
            </div>
        </div>
    );
};

export default Home;
