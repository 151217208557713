import { AxiosResponse } from 'axios';
import Api from '../helpers/Api';
import Notifier from '../helpers/Notifier';

/**
 * APIs class.
 */
export default class GenericAPIs {
    static headers: object | null = null;

    /**
     * Get something from API.
     *
     * @param url
     * @param query
     * @param notifySuccess
     */
    public static async apiGetCall(url: string, query: any = null, notifySuccess: boolean = true) {
        let options: any = {params: query};
        const title = 'Une erreur est survenue';

        if (null !== this.headers) {
            options.headers = this.headers;
        }

        // Call API baseUrl with GET method on /url
        return Api.get(url, options)
            .then((response: AxiosResponse) => {
                // Message for notifier and console
                const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;
                // If we have a response, we create a success notification and log in console
                notifySuccess && Notifier.createNotification('Successfully called Payment API', msg, 'success', 10000);
                /*query ? console.log(`${msg} with query :`, query, 'returns: ', response) : console.log(`${msg} returns :`, response);*/

                // We manage the fact that the response can be null
                null === response.data && Notifier.createNotification(title, `L'API n'a retourné aucune réponse.`, 'warning');

                // We also return this response
                return response.data;
            })
            .catch((error: any) => {
                // Message for notifier and console
                //const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
                //console.log(`${msg} with query :`, query);

                // If we have an error, we create a danger notification
                Notifier.createNotification(title, `Impossible de communiquer avec l'API`, 'danger', 10000);

                return null;
            });
    }

    /**
     * Post something on API.
     *
     * @param url
     * @param body
     * @param notifySuccess
     * @param notifyResponseNull
     * @param notifyFail
     */
    public static async apiPostCall(url: string, body: any, notifySuccess: boolean = true, notifyResponseNull: boolean = true, notifyFail: boolean = true) {
        let options = undefined;

        if (null !== this.headers) {
            options = this.headers;
        }

        // Call API baseUrl with POST method on /url
        return Api.post(url, body, options)
            .then((response: AxiosResponse) => {
                return this.manageCallWithBodySuccess(response, body, notifySuccess, notifyResponseNull);
            })
            .catch((error: any) => {
                return this.manageCallWithBodyFail(error, body, notifyFail);
            });
    }

    /**
     * Define what to do after a successfull API call with a body
     *
     * @param response
     * @param body
     * @param notifySuccess
     * @param notifyResponseNull
     */
    protected static manageCallWithBodySuccess(response: AxiosResponse, body: any, notifySuccess: boolean, notifyResponseNull: boolean) {
        const title = 'Une erreur est survenue';
        // Message for notifier and console
        const msg = `${response.config.method?.toUpperCase()} ${response.config.baseURL}${response.config.url}`;

        // If we have a response, we create a success notification and log in console
        notifySuccess && Notifier.createNotification('Successfully called Payment API', msg, 'success', 10000);
        //console.log(`${msg} with body :`, body, 'returns :', response);

        // We manage the fact that the response can be null
        null === response.data && notifyResponseNull && Notifier.createNotification(title, `L'API n'a retourné aucune réponse.`, 'warning', 10000);

        // We also return this response
        return response.data;
    }

    /**
     * Define what to do after a fail API call with a body
     *
     * @param error
     * @param body
     * @param notifyFail
     */
    protected static manageCallWithBodyFail(error: any, body: any, notifyFail: boolean) {
        // Message for console
        //const msg = `${error.response.config.method?.toUpperCase()} ${error.response.config.baseURL}${error.response.config.url}`;
        //console.log(`${msg} with body :`, body);

        //We create a danger notification for each errors from our API response
        if (403 === error.response.status) {
            notifyFail && Notifier.notifyApiError({origin: 'Role', message: 'Vous n\'avez pas les droits suffisants.'});
        } else if (typeof error.response.data.errors !== 'undefined') {
            notifyFail && Notifier.notifyApiErrors(error.response.data.errors);
        } else {
            notifyFail && Notifier.notifyApiError(error.response.data);
        }

        return null;
    }
};
