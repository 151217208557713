import React, {useEffect, useRef, useState} from "react";
import HeadbandStep from "../../heandbandStep/HeadbandStep";
import {loadStripe} from "@stripe/stripe-js";
import UrlInspector from "../../../helpers/UrlInspector";
import {useLocation, useHistory} from "react-router-dom";
import PaymentService from "../../../services/PaymentService";
import ITransaction from "../../../interfaces/ITransaction";
import TransactionAPIs from "../../../APIs/TransactionAPIs";
import Spinner from "../../spinner/Spinner";
import Logger from "../../../helpers/Logger";
import AppStorage from "../../../helpers/AppStorage";
import Notifier from "../../../helpers/Notifier";
import './ConfirmationForm.scss';
import Utils from "../../../helpers/Utils";
import {LOADING, UNABLE_TO_LOAD_FORM, USER_ON_CONFIRMATION_PAGE} from "../../../helpers/Message";

const ConfirmationForm: React.FC<{ typeForm: string }> = props => {
    const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
    const urlInspector = new UrlInspector(useLocation());
    const transactionToken: any = urlInspector.getParams('transactionToken') ?? AppStorage.getTransactionToken();

    const [transaction, setTransaction] = useState<ITransaction | null>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [spinnerMsg, setSpinnerMsg] = useState<string>(LOADING);
    const [iCanSetSpinnerMsg, setICanSetSpinnerMsg] = useState<boolean>(false);

    const isFirst = useRef<boolean>(true);
    const confirmationBtnRef = useRef<any>();
    const returnBtnRef = useRef<any>();

    // Use of react-router-dom
    const history = useHistory()


    // Log informations
    useEffect(() => {
        if (isFirst.current) {
            Logger.log(USER_ON_CONFIRMATION_PAGE);
        }

        isFirst.current = false;
    });

    // Get transaction thanks to token
    useEffect(() => {
        if (null !== transactionToken && typeof transactionToken !== 'undefined') {
            const status = ['confirmation-info', 'pending'];

            TransactionAPIs.getTransactions({token: transactionToken, status: status})
                .then((response: ITransaction | null) => {
                    if (null !== response && status.includes(response.status)) {
                        setTransaction(response);
                        setIsShow(true);
                    } else {
                        setICanSetSpinnerMsg(true);
                    }

                    history.replace({});
                })
                .catch(_ => {
                    setICanSetSpinnerMsg(true);
                });
        }
    }, [history, transactionToken]);

    // Set new msg after 10 seconds
    useEffect(() => {
        iCanSetSpinnerMsg && setTimeout(() => {
            setSpinnerMsg(UNABLE_TO_LOAD_FORM);
        }, 10000);
    });

    // Redirect to payment
    const checkoutSessionStripe = async () => {
        if (null !== transaction && typeof transaction !== 'undefined') {
            // Call your backend to create the Checkout Session—see previous step
            let sessionId: string | null = null;
            let transactionToken: string | null = null;
            const data = await PaymentService.createCheckoutSession(transaction);

            if (null !== data) {
                sessionId = data.sessionId
                transactionToken = data.transactionToken
            }
            // When the customer clicks on the button, redirect them to Checkout.
            const stripe = await stripePromise;
            const title = 'Une erreur est survenue';

            if (null === stripe) {
                Notifier.createNotification(title, 'Connexion impossible avec le module de paiement.', 'danger', 10000);

                return false;
            }

            if (null === sessionId) {
                Notifier.createNotification(title, 'Impossible de trouver une session de paiement.', 'danger', 10000);

                return false;
            }

            if (null !== transactionToken) {
                AppStorage.storeTransactionToken(transactionToken);
            }

            const {error} = await stripe.redirectToCheckout({sessionId});
            if (error) {
                Notifier.createNotification(title, 'Une erreur est survenue lors de la redirection vers la page de paiement.', 'danger', 10000);
                Notifier.createNotification(title, String(error.message), 'danger', 10000);
            }

            return false;
        }
    };

    // Redirect to payment
    const handleConfirmationClick = async () => {
        Utils.useRefBtnDisableTreatment([confirmationBtnRef, returnBtnRef], 'buttonDefault transition500', 'btnDisabled', 60000);

        return checkoutSessionStripe();
    };

    // Redirect to precedent form
    const handleReturnClick = async () => {
        Utils.useRefBtnDisableTreatment([returnBtnRef, confirmationBtnRef], 'buttonDefault transition500', 'btnDisabled', 10000);

        history.push(`/informations?typeForm=${props.typeForm}&transactionToken=${transactionToken}`);
    };

    return (
        <>
            {
                isShow && null !== transaction && typeof transaction !== 'undefined' ?
                <>
                    <HeadbandStep type={`payment`} step={`confirmation`}/>
                    <div className="blockContainer_default">
                        <div className="formContainer">
                            <div className="titreForm">
                                <h2 className="titreContent">Confirmation des données</h2>
                            </div>
                            <div className="formStructure bxrow">
                                <div className="bxd6 bxt12 formSectionContainer">
                                    <div className="formSectionTitre">
                                        <p className="titreContent">Vos informations personnelles</p>
                                    </div>
                                    <div className="formContent">
                                        <div className="tabDataContent">
                                            <p className="labelData">Civilité <span className="data">{transaction.civility === 1 ? 'Monsieur' : 'Madame'}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Prénom <span className="data">{transaction.firstname}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Nom <span className="data">{transaction.lastname}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">E-mail <span className="data">{transaction.email}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Téléphone <span className="data">{transaction.phone}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Nom de société <span className="data">{transaction.companyName}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Siren / Siret <span className="data">{transaction.sirenSiret}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bxd6 bxt12 formSectionContainer">
                                    <div className="formSectionTitre">
                                        <p className="titreContent">Adresse de facturation</p>
                                    </div>
                                    <div className="formContent">
                                        <div className="tabDataContent">
                                            <p className="labelData">Adresse postale <span className="data">{transaction.billingAddress.line1} {transaction.billingAddress.postalCode} {transaction.billingAddress.city} {transaction.billingAddress.country}</span></p>
                                        </div>
                                    </div>
                                    <div className="formSectionTitre">
                                        <p className="titreContent">Adresse de livraison</p>
                                    </div>
                                    <div className="formContent">
                                        <div className="tabDataContent">
                                            <p className="labelData">Nom <span className="data">{transaction.order.shipping.name}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Téléphone <span className="data">{transaction.order.shipping.phone}</span></p>
                                        </div>
                                        <div className="tabDataContent">
                                            <p className="labelData">Adresse postale <span className="data">{transaction.order.shipping.address.line1} {transaction.order.shipping.address.postalCode} {transaction.order.shipping.address.city} {transaction.order.shipping.address.country}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="buttonConfirmationContainer">
                                <div className="divButton start">
                                    <button className="buttonDefault transition500" type="submit" ref={returnBtnRef} onClick={handleReturnClick}>Retour</button>
                                </div>
                                <div className="divButton end">
                                    <button className="buttonDefault transition500" type="submit" ref={confirmationBtnRef} onClick={handleConfirmationClick}>Paiement</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                    :
                    <Spinner msg={spinnerMsg}/>
            }

        </>
    );
};

export default ConfirmationForm;
