import queryString, {ParsedQuery} from "query-string";

/**
 * UrlInspector class.
 */
class UrlInspector {
    urlParams: ParsedQuery;
    location: any;

    /**
     * Constructor.
     *
     * @param location
     */
    constructor(location: any) {
        this.location = location;
        this.urlParams = queryString.parse(location.search);
    }

    /**
     * Get url params.
     *
     * @param params
     */
    public getParams(params: string): string[] | string | null | undefined {
        return this.urlParams[params] ?? null
    }
}

export default UrlInspector;
