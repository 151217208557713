import React from 'react';
import GridLoader from "react-spinners/GridLoader";
import './Spinner.scss';

type Props = {
    msg: string | null
};

const Spinner: React.FC<Props> & { defaultProps: Partial<Props> } = props => {
    return (
        <div className="spinner">
            <GridLoader size={10} margin={5} color={"#808080"}/>
            {
                null !== props.msg && '' !== props.msg &&
                <div className="msg">
                    {props.msg}
                </div>
            }

        </div>
    );
};

Spinner.defaultProps = {
    msg: undefined
};

export default Spinner;
