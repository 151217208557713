import {FormikValues} from "formik";
import PaymentAPIs from "../APIs/PaymentAPIs";
import ITransaction from "../interfaces/ITransaction";
import IpStorage from "../helpers/IpStorage";

/**
 * Class PaymentService.
 */
class PaymentService {
    /**
     * Post informations values.
     */
    public postInformations = (values: FormikValues, transactionToken: string) => {
        const ipv4: string | null | undefined = IpStorage.getIpClient();

        const body: object = {
            civility: values.civility,
            lastname: values.lastname,
            firstname: values.firstname,
            email: values.email,
            phone: values.phone,
            companyName: values.companyName,
            sirenSiret: values.sirenSiret,
            billingAddress: {
                city: values.billingAddressCity,
                country: values.billingAddressCountry,
                line1: values.billingAddressLine1,
                postalCode: values.billingAddressPostalCode,
            },
            shipping: {
                address: {
                    city: values.shippingAddressCity,
                    country: values.shippingAddressCountry,
                    line1: values.shippingAddressLine1,
                    postalCode: values.shippingAddressPostalCode,
                },
                name: values.shippingName,
                phone: values.shippingPhone,
            },
            transactionToken: transactionToken,
            ipClient: typeof ipv4 !== 'undefined' ? ipv4 : null,
        };

        return PaymentAPIs.postInformationsPayment(body);
    };

    /**
     * Create checkout session on server.
     *
     * @param transaction
     */
    public createCheckoutSession = (transaction: ITransaction) => {
        return PaymentAPIs.postSessionPayment({transactionToken: transaction.token});
    };
}

export default new PaymentService();
