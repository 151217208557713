import React from 'react';
import {Field, FormikProps} from "formik";

const ShippingFields: React.FC<{ formikProps: FormikProps<any>, fieldClass: Function }> = props => {

    return (
        <>
            <div className="formContent labelContent bxd12">
                <label className="LabelDefault" htmlFor="shippingAddress" >Adresse de livraison</label>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingName, props.formikProps.errors.shippingName, props.formikProps.values.shippingName)}>
                    <Field className="inputContent" type="text" name="shippingName" placeholder="Nom"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingPhone, props.formikProps.errors.shippingPhone, props.formikProps.values.shippingPhone)}>
                    <Field className="inputContent" type="text" name="shippingPhone" placeholder="Numéro de téléphone"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingAddressLine1, props.formikProps.errors.shippingAddressLine1, props.formikProps.values.shippingAddressLine1)}>
                    <Field className="inputContent" type="text" name="shippingAddressLine1" placeholder="Adresse"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingAddressPostalCode, props.formikProps.errors.shippingAddressPostalCode, props.formikProps.values.shippingAddressPostalCode)}>
                    <Field className="inputContent" type="text" name="shippingAddressPostalCode" placeholder="Code postal"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingAddressCity, props.formikProps.errors.shippingAddressCity, props.formikProps.values.shippingAddressCity)}>
                    <Field className="inputContent" type="text" name="shippingAddressCity" placeholder="Ville"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.shippingAddressCountry, props.formikProps.errors.shippingAddressCountry, props.formikProps.values.shippingAddressCountry)}>
                    <Field className="inputContent" type="text" name="shippingAddressCountry" placeholder="Pays"/>
                </div>
            </div>

        </>
    );
};

export default ShippingFields;
