/**
 * Class IpStorage.
 */
export default class IpStorage {
    private static readonly LOCAL_STORAGE_IP_CLIENT = 'ipClientPayment';

    /**
     * Store Ipv4 client.
     */
    public static storeIpClient(ipClient: string) {
        localStorage.setItem(this.LOCAL_STORAGE_IP_CLIENT, ipClient);
    }

    /**
     * Get ipv4 client from localStorage.
     */
    public static getIpClient(): string | undefined {
        const ipClient = localStorage.getItem(this.LOCAL_STORAGE_IP_CLIENT);

        return ipClient && '' !== ipClient ? ipClient : undefined;
    }

}
