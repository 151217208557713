/**
 * Class Utils.
 */
export default class Utils {
    /**
     * Btn useRef treatment.
     */
    public static useRefBtnDisableTreatment(buttons: any [], baseCss: string, disabledCss: string, ms: number = 3000): boolean {
        let isTreated = false;

        buttons && 0 < buttons.length && buttons.forEach((btn: any) => {
            if (btn && btn.current) {
                btn.current.setAttribute('disabled', 'disabled');
                btn.current.setAttribute('class', `${baseCss} ${disabledCss}`);

                setTimeout(function () {
                    if (btn && btn.current) {
                        btn.current.removeAttribute('disabled');
                        btn.current.setAttribute('class', baseCss);
                    }
                }, ms);

                isTreated = true;
            }
        });


        return isTreated;
    }
}
