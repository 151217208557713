export const LOADING = 'Chargement...';
export const UNABLE_TO_LOAD_FORM = 'Impossible de charger le formulaire, des informations sont manquantes';
export const LOADING_NOT_POSSIBLE = 'Chargement impossible, des informations sont manquantes';
export const USER_ON_PAYMENT_MODULE = 'Utilisateur sur le module de paiement.';
export const USER_ON_INFORMATION_PAGE = 'Utilisateur sur la page d\'informations.';
export const USER_ON_CONFIRMATION_PAGE = 'Utilisateur sur la page de confirmation.';
export const USER_ON_CANCELED_PAGE = 'Utilisateur sur la page cancel.';
export const USER_ON_SUCCEEDED_PAGE = 'Utilisateur sur la page success.';
export const EXPIRED_LINK = 'Ce lien a expiré. Ce qui signifie que votre paiement a déjà été traité ou que votre session a expiré.';
export const API_ERROR = 'API - Une erreur est survenue';
export const AUTHENTICATION_NOT_POSSIBLE = 'Authentification impossible.';
export const AUTHENTICATION_ERROR = 'Une erreur est survenue lors de l\'authentification';
export const PAYMENT_NOT_EXIST = 'Assurez-vous que votre paiement a bien été pris en compte.';
export const TRANSACTION_ERROR = 'La communication est impossible.';
