import React from "react";
import './HeadbandLogo.scss';

const HeadbandLogo: React.FC<{logo: string}> = props => {
    return (
        <>
            <div className="HeaderLogo">
                <img src={require(`../../img/${props.logo}.svg`)} alt="logo" className="logo"/>
            </div>
        </>
    );
};

export default HeadbandLogo;
