import React from 'react';
import {Field, FormikProps} from "formik";

const BillingAddressFields: React.FC<{ formikProps: FormikProps<any>, fieldClass: Function }> = props => {

    return (
        <>
            <div className="formContent labelContent bxd12">
                <label className="LabelDefault" htmlFor="billingAddress" >Adresse de facturation</label>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.billingAddressLine1, props.formikProps.errors.billingAddressLine1, props.formikProps.values.billingAddressLine1)}>
                    <Field className="inputContent" type="text" name="billingAddressLine1" placeholder="Adresse"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.billingAddressPostalCode, props.formikProps.errors.billingAddressPostalCode, props.formikProps.values.billingAddressPostalCode)}>
                    <Field className="inputContent" type="text" name="billingAddressPostalCode" placeholder="Code postal"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.billingAddressCity, props.formikProps.errors.billingAddressCity, props.formikProps.values.billingAddressCity)}>
                    <Field className="inputContent" type="text" name="billingAddressCity" placeholder="Ville"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <div className={props.fieldClass(props.formikProps.touched.billingAddressCountry, props.formikProps.errors.billingAddressCountry, props.formikProps.values.billingAddressCountry)}>
                    <Field className="inputContent" type="text" name="billingAddressCountry" placeholder="Pays"/>
                </div>
            </div>
        </>
    );
};

export default BillingAddressFields;
