import React from 'react';
import ITransaction from "../../../interfaces/ITransaction";
import './HektorForm.scss';
import GenericForm from "../genericForm/GenericForm";

const HektorForm: React.FC<{ typeForm: string, transaction: ITransaction }> = props => {
    return (
        <>
            <GenericForm typeForm={props.typeForm} transaction={props.transaction}/>
        </>
    );
};

export default HektorForm;
