/**
 * JwtStorage class.
 */
export default class JwtStorage {
    private static readonly LOCAL_STORAGE_JWT = 'jwtPayment';

    /**
     * Check authentication.
     */
    public static isAuthenticated(): boolean {
        return null !== this.getJwt();
    }

    /**
     * Get full jwt header.
     */
    public static getAuthorizationHeader(): { headers: object } {
        return  {
            headers: JwtStorage.getAuthorization(),
        };
    }

    /**
     * Get jwt authorization.
     */
    public static getAuthorization(): { JWT: string } {
        return {
            'JWT': this.getJwt() ?? '',
        };
    }

    /**
     * Store token in localStorage.
     *
     * @param jwt
     */
    public static storeJwt(jwt: string): void {
        localStorage.setItem(this.LOCAL_STORAGE_JWT, jwt);
    }

    /**
     * Clear token from localStorage.
     */
    public static clear(): void {
        localStorage.removeItem(this.LOCAL_STORAGE_JWT);
    }

    /**
     * Get token from localStorage.
     */
    public static getJwt(): string | null {
        return localStorage.getItem(this.LOCAL_STORAGE_JWT) ?? null;
    }
}
