import React from "react";
import './HeadbandStep.scss';
import headbandStepType from "../../configs/headbandStepType";

const HeadbandStep: React.FC<{type: string, step: string}> = props => {
    return (
        <>
            <div className="stepContainer">
                <div className="stepStructure">
                    {
                        headbandStepType[props.type].map((params: any) =>
                            <div key={params.key} className={params.toActivate.includes(props.step) ? 'stepContent active' : 'stepContent'}>
                                <span className="spanIcon"></span>
                                {params.label}
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default HeadbandStep;
