/**
 * Class AppStorage.
 */
export default class AppStorage {
    private static readonly LOCAL_STORAGE_TYPE_FORM = 'typeFormPayment';
    private static readonly LOCAL_STORAGE_TRANSACTION_TOKEN = 'transactionTokenPayment';

    /**
     * Store typeForm.
     */
    public static storeTypeForm(typeForm: string) {
        localStorage.setItem(this.LOCAL_STORAGE_TYPE_FORM, typeForm);
    }

    /**
     * Get typeForm from localStorage.
     */
    public static getTypeForm(): string | undefined {
        return localStorage.getItem(this.LOCAL_STORAGE_TYPE_FORM) ?? undefined;
    }

    /**
     * Store transactionToken.
     */
    public static storeTransactionToken(transactionToken: string) {
        localStorage.setItem(this.LOCAL_STORAGE_TRANSACTION_TOKEN, transactionToken);
    }

    /**
     * Get transactionToken from localStorage.
     */
    public static getTransactionToken(): string | undefined {
        return localStorage.getItem(this.LOCAL_STORAGE_TRANSACTION_TOKEN) ?? undefined;
    }

}
