import React, {useEffect, useRef, useState} from "react";
import UrlInspector from "../../../helpers/UrlInspector";
import {useHistory, useLocation} from "react-router-dom";
import ITransaction from "../../../interfaces/ITransaction";
import TransactionAPIs from "../../../APIs/TransactionAPIs";
import Spinner from "../../spinner/Spinner";
import Logger from "../../../helpers/Logger";
import AppStorage from "../../../helpers/AppStorage";
import {LOADING, UNABLE_TO_LOAD_FORM, USER_ON_INFORMATION_PAGE} from "../../../helpers/Message";

const InformationsForm: React.FC<{ typeForm: string, component: React.FC<any>, transactionStatuses: string[] }> = props => {
    const urlInspector = new UrlInspector(useLocation());
    const transactionToken: any = urlInspector.getParams('transactionToken') ?? AppStorage.getTransactionToken();

    const [transaction, setTransaction] = useState<ITransaction | null>();
    const [spinnerMsg, setSpinnerMsg] = useState<string>(LOADING);
    const [iCanSetSpinnerMsg, setICanSetSpinnerMsg] = useState<boolean>(false);

    const isFirst = useRef<boolean>(true);

    // Use of react-router-dom
    const history = useHistory();

    // Log informations
    useEffect(() => {
        if (isFirst.current) {
            Logger.log(USER_ON_INFORMATION_PAGE);
        }

        isFirst.current = false;
    });

    // Get transaction thanks to token
    useEffect(() => {
        if (null !== transactionToken && typeof transactionToken !== 'undefined') {
            TransactionAPIs.getTransactions({token: transactionToken, status: props.transactionStatuses})
                .then((response: ITransaction | null) => {
                    if (null !== response && props.transactionStatuses.includes(response.status)) {
                        setTransaction(response);
                        AppStorage.storeTransactionToken(transactionToken);
                    } else {
                        setICanSetSpinnerMsg(true);
                    }

                    history.replace({});
                })
                .catch(_ => {
                    setICanSetSpinnerMsg(true);
                });
        }
    }, [history, props.transactionStatuses, transactionToken]);

    // Set new msg after 10 seconds
    useEffect(() => {
        iCanSetSpinnerMsg && setTimeout(() => {
            setSpinnerMsg(UNABLE_TO_LOAD_FORM);
        }, 10000);
    }, [iCanSetSpinnerMsg]);

    return (
        <>
            {
                null !== transaction && typeof transaction !== 'undefined' ?
                    React.createElement(props.component, {
                        typeForm: props.typeForm,
                        transaction: transaction,
                    })
                    :
                    <Spinner msg={spinnerMsg}/>
            }
        </>
    );
};

export default InformationsForm;
