import React from 'react';
import {Field, FormikProps} from "formik";

const PersonFields: React.FC<{ formikProps: FormikProps<any>, fieldClass: Function }> = props => {
    return (
        <>
            <div className="formSectionTitre bxd12">
                <p className="titreContent">Vos informations personnelles</p>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="civility" >Civilité *</label>
                <div className={props.fieldClass(props.formikProps.touched.civility, props.formikProps.errors.civility, props.formikProps.values.civility)}>
                    <Field className="inputContent" as="select" name="civility" >
                        <option value={1}>Monsieur</option>
                        <option value={2}>Madame</option>
                    </Field>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="lastname" >Nom *</label>
                <div className={props.fieldClass(props.formikProps.touched.lastname, props.formikProps.errors.lastname, props.formikProps.values.lastname)}>
                    <Field className="inputContent" type="text" name="lastname" placeholder="Nom"/>
                </div>
            </div>
            <div className="formContent bxd4 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="firstname" >Prénom *</label>
                <div className={props.fieldClass(props.formikProps.touched.firstname, props.formikProps.errors.firstname, props.formikProps.values.firstname)}>
                    <Field className="inputContent" type="text" name="firstname" placeholder="Prénom"/>
                </div>
            </div>
            <div className="formContent bxd6 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="email" >Email *</label>
                <div className={props.fieldClass(props.formikProps.touched.email, props.formikProps.errors.email, props.formikProps.values.email)}>
                    <Field className="inputContent" type="email" name="email" placeholder="Email"/>
                </div>
            </div>
            <div className="formContent bxd6 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="phone" >Numéro de téléphone</label>
                <div className={props.fieldClass(props.formikProps.touched.phone, props.formikProps.errors.phone, props.formikProps.values.phone)}>
                    <Field className="inputContent" type="tel" name="phone" placeholder="Numéro de téléphone"/>
                </div>
            </div>
            <div className="formContent bxd6 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="companyName" >Nom de société</label>
                <div className={props.fieldClass(props.formikProps.touched.companyName, props.formikProps.errors.companyName, props.formikProps.values.companyName)}>
                    <Field className="inputContent" type="text" name="companyName" placeholder="Nom de société"/>
                </div>
            </div>
            <div className="formContent bxd6 bxt6 bxm12">
                <label className="LabelDefault" htmlFor="sirenSiret" >Siren / Siret</label>
                <div className={props.fieldClass(props.formikProps.touched.sirenSiret, props.formikProps.errors.sirenSiret, props.formikProps.values.sirenSiret)}>
                    <Field className="inputContent" type="text" name="sirenSiret" placeholder="Siren / Siret"/>
                </div>
            </div>
        </>
    );
};

export default PersonFields;
