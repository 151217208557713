import GenericAPIs from './GenericAPIs';
import JwtStorage from "../helpers/JwtStorage";

/**
 * TransactionAPIs class.
 */
export default class TransactionAPIs {

    /**
     * Url on API for the Transaction resource
     */
    private static resourceUrl: string = 'transactions';

    /**
     * Get Transactions.
     *
     * @param query
     */
    public static async getTransactions(query: any) {
        GenericAPIs.headers = JwtStorage.getAuthorization();

        // do not notify on success
        return GenericAPIs.apiGetCall(`${this.resourceUrl}`, query, false);
    }
};
