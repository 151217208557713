import React from 'react';
import {Form, FormikProps} from "formik";
import BillingAddressFields from "../../formFields/BillingAddressFields";
import ShippingFields from "../../formFields/ShippingFields";
import PersonFields from "../../formFields/PersonFields";

const MinimalForm: React.FC<{ formikProps: FormikProps<any> }> = props => {
    const fieldClass = (touched: any, error: any, value: any) => {
        let className: string;
        className = 'inputDefaultContent';

        if (touched && error) {
            className = "inputDefaultContent invalidForm";
        }

        if ((touched || value) && !error) {
            className = "inputDefaultContent validForm";
        }

        if (('' === value || !touched) && !error) {
            className = "inputDefaultContent";
        }

        return className;
    };

    return (
        <>
            <Form className="formContainer">
                <div className="titreForm">
                    <h2 className="titreContent">Informations de paiement</h2>
                </div>
                <div className="formStructure bxrow">
                    <PersonFields formikProps={props.formikProps} fieldClass={fieldClass}/>
                    <BillingAddressFields formikProps={props.formikProps} fieldClass={fieldClass}/>
                    <ShippingFields formikProps={props.formikProps} fieldClass={fieldClass}/>
                </div>
                <div className="buttonDefaultContainer">
                    <button className="buttonDefault transition500" type="submit"
                            disabled={props.formikProps.isSubmitting}>
                        Continuer
                    </button>
                </div>
            </Form>
        </>
    );
};

export default MinimalForm;
