import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import PaymentAPIs from "../../APIs/PaymentAPIs";
import ITransaction from "../../interfaces/ITransaction";
import Spinner from "../spinner/Spinner";
import UrlInspector from "../../helpers/UrlInspector";
import Logger from "../../helpers/Logger";
import sendSvg from "../../img/send.svg";
import crossSvg from "../../img/cross.svg";
import UrlQueryDecoder from "../../helpers/UrlQueryDecoder";
import './CanceledPayment.scss';
import {EXPIRED_LINK, USER_ON_CANCELED_PAGE} from "../../helpers/Message";

const CanceledPayment: React.FC = () => {
    const urlInspector = new UrlInspector(useLocation());
    const token: any = urlInspector.getParams('paymentToken');

    const [transaction, setTransaction] = useState<ITransaction | null>();
    const [spinnerMsg, setSpinnerMsg] = useState<string>('');

    const isFirst = useRef<boolean>(true);

    // Use of react-router-dom
    const history = useHistory();

    useEffect(() => {
        if (null !== token) {
            const urlQueryDecoder = new UrlQueryDecoder(token);
            const body = {transactionToken: urlQueryDecoder.getTransactionToken()};
            const authHeaders = urlQueryDecoder.getAuthorizationHeader();

            // Log informations
            if (isFirst.current) {
                Logger.log(USER_ON_CANCELED_PAGE, authHeaders);
            }

            // Post canceled payment
            PaymentAPIs.postCanceledPayment(body, authHeaders)
                .then((response: ITransaction | null) => {
                    if (null !== response) {
                        setTransaction(response);
                    } else {
                        setTransaction(null);
                        setSpinnerMsg(EXPIRED_LINK);
                    }
                });
        } else {
            setTransaction(null);
        }

        history.replace({});

        isFirst.current = false;
    }, [history, token]);

    return (
        <>
            {
                typeof transaction !== 'undefined' && null !== transaction ?
                    <div className="sendPaymentContainer canceled">
                        <div className="sendPaymentStructure">
                            <div className="imgContent">
                                <img src={sendSvg} alt="icon" className="icon"/>
                                <img src={crossSvg} alt="icon" className="iconCancel"/>
                            </div>
                            <div className="sendPaymentContent">
                                <p className="titre">Paiement annulé</p>
                                <p className="text">Votre paiement a été annulé.</p>
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner msg={spinnerMsg}/>
            }
        </>
    );
};

export default CanceledPayment;
