import Axios from "axios";

/**
 * IpAPIs class.
 */
export default class IpAPIs {
    /**
     * Get ip.
     */
    public static async getIpv4() {
        return Axios.get(`${process.env.REACT_APP_GET_IP_URL}?format=json`);
    }
}
