import {store} from 'react-notifications-component';
import IApiError from '../interfaces/IApiError';
import 'react-notifications-component/dist/theme.css';
import {API_ERROR, AUTHENTICATION_NOT_POSSIBLE, PAYMENT_NOT_EXIST, TRANSACTION_ERROR} from "./Message";

/**
 * Notifier class.
 */
class Notifier {
    /**
     * Create a notification on App
     *
     * @param title
     * @param message
     * @param type
     * @param duration
     */
    public createNotification(title: string, message: string, type: 'success' | 'danger' | 'info' | 'default' | 'warning' = 'danger', duration: number = 20000) {
        store.addNotification({
            title: title,
            message: message.toString(),
            type: type,
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: duration,
                onScreen: true
            }
        });
    }

    /**
     * Create notifications from an array of API errors.
     *
     * @param errors
     * @param duration
     */
    public notifyApiErrors(errors: IApiError[], duration: number = 10000) {
        errors.map((error: IApiError) => {
            //const title = `Erreur Payment API sur le champ '${error.origin}'`;
            const title = API_ERROR;
            const message = this.errorMessageConverter(error.message);

            this.createNotification(title, message, 'danger', duration);

            return null;
        })
    }

    /**
     * Create notifications of API errors
     *
     * @param error
     * @param duration
     */
    public notifyApiError(error: IApiError, duration: number = 10000) {
        const title = API_ERROR;
        const message = this.errorMessageConverter(error.message);

        this.createNotification(title, message, 'danger', duration);

        return null;
    }

    /**
     * Error message converter.
     *
     * @param errorMessage
     */
    public errorMessageConverter(errorMessage: string) {
        let message: string;

        message = 'Expired JWT Token' === errorMessage
        || 'JWT Token not found' === errorMessage
        || 'Invalid JWT Token' === errorMessage
            ? AUTHENTICATION_NOT_POSSIBLE : errorMessage;

        message = 'Session Id is null.' === errorMessage
        || 'Session object not exist.' === errorMessage
        || 'Payment not exist.' === errorMessage
            ? PAYMENT_NOT_EXIST : message;

        message = 'Impossible to decode the requested body.' === errorMessage
        || 'Error with Stripe API.' === errorMessage
        || 'TransactionToken not found in request.' === errorMessage
        || 'Transaction not found with these params.' === errorMessage
        || 'Real status not match.' === errorMessage
            ? TRANSACTION_ERROR : message;

        return message;
    }
}

export default new Notifier();
